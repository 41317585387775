import { InputProps } from "@/remotion/Root";
import { GameCompositionProps } from "./Composition";
import axios from "axios";

// Singleton-Promise für die API-Anfrage
let fetchPromise: Promise<{ balance: number; txs: number }> | null = null;

// Mock
/* fetchPromise = new Promise((resolve) => {
  resolve({ balance: 0, txs: 1 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 100000, txs: 100 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 499999, txs: 1000 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 500000, txs: 10000 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 700000, txs: 1000000 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 999999, txs: 100000000 });
});
fetchPromise = new Promise((resolve) => {
  resolve({ balance: 1000000, txs: 100000000 });
}); */

const fetchBlockchainData = async (btcAddress: string) => {
  if (!fetchPromise) {
    fetchPromise = axios
      .get(`https://blockchain.info/balance?active=${btcAddress}`)
      .then((response) => {
        const balance = response.data[btcAddress].final_balance;
        const txs = response.data[btcAddress].n_tx;
        return { balance, txs };
      })
      .catch((error) => {
        // Setze fetchPromise zurück, wenn ein Fehler auftritt, um erneute Versuche zu ermöglichen
        fetchPromise = null;
        throw error;
      });
  }
  return fetchPromise;
};

export const prepareGameCompositionProps = async (
  inputProps: InputProps
): Promise<GameCompositionProps> => {
  // Beispiel-BTC-Adresse (kann natürlich angepasst werden)
  const btcAddress = "bc1q0rcauc04lgecq95wufxvswm8swsk2vgjjfdp8r";

  // Daten aus einer Blockchain-API abrufen
  const { balance, txs } = await fetchBlockchainData(btcAddress);

  const asciiBase = " .:-";
  const digits = "12345678";
  const numberOfRandomDigits = 3;

  let asciiCharacters = asciiBase;
  for (let i = 0; i < numberOfRandomDigits; i++) {
    asciiCharacters += digits[Math.floor(Math.random() * digits.length)];
  }

  const resolution = Math.random() * (0.25 - 0.15) + 0.15;

  const props: GameCompositionProps = {
    ...inputProps,
    balance,
    txs,
    btcAddress,
    asciiCharacters,
    resolution,
    audioNumber: Math.floor(Math.random() * 11) + 1,
    audioSpeechNumber: Math.floor(Math.random() * 4) + 1,
  };

  return props;
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
