import React, {
  useRef,
  useEffect,
  useState,
  CSSProperties,
  useCallback,
} from "react";
import { useCurrentFrame, useVideoConfig, random } from "remotion";

type GlitchCanvasExtendedProps = {
  /**
   * Entweder der Pfad zu einem Bild,
   * oder zu einem Video.
   */
  src: string;
  /**
   * Legt fest, ob src ein Video ist (true)
   * oder ein Bild (false).
   */
  isVideo?: boolean;
  /**
   * Anzahl der horizontalen Streifen pro Glitch-Layer.
   */
  numberOfSlices?: number;
  /**
   * Wie stark die Streifen maximal verschoben werden (in Pixel).
   */
  glitchIntensity?: number;
  /**
   * Nur alle x Frames glitchen (z.B. 3 bedeutet,
   * dass nur bei frame % 3 === 0 ein neuer Zufallswert erzeugt wird).
   */
  glitchFrequency?: number;
  /**
   * Anzahl, wie viele Glitch-Layer übereinander
   * gerendert werden sollen.
   */
  layers?: number;
  /**
   * Stärke der Farbverschiebung (Hue-Rotate in Grad).
   */
  colorShift?: number;
  /**
   * Zusätzlicher Inline-Style für das Canvas-Element.
   */
  style?: CSSProperties;
};

/**
 * Diese erweiterte Komponente zeichnet pro Frame einen Canvas
 * und manipuliert Streifen ("Slices") zufällig horizontal.
 * Optional kann das Ganze mehrfach übereinandergelegt werden,
 * mit einem leichten Hue-Rotate (colorShift).
 */
export const GlitchCanvasExtended: React.FC<GlitchCanvasExtendedProps> = ({
  src,
  isVideo = false,
  numberOfSlices = 20,
  glitchIntensity = 40,
  glitchFrequency = 1,
  layers = 1,
  colorShift = 0,
  style = {},
}) => {
  const frame = useCurrentFrame();
  const { width, height } = useVideoConfig();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  // Video-Element zwischenspeichern, falls wir isVideo = true verwenden
  const videoRef = useRef<HTMLVideoElement | null>(null);

  /**
   * Für Videos: Einmalig das <video>-Element anlegen
   * und das src setzen. Dann wird es pro Frame gezeichnet.
   */
  useEffect(() => {
    if (!isVideo) return;
    const vid = document.createElement("video");
    vid.src = src;
    vid.muted = true;
    vid.autoplay = true;
    vid.loop = true;
    videoRef.current = vid;
    // Wir starten das Video ab, sobald es geladen ist
    vid.addEventListener("loadeddata", () => {
      vid.play();
    });
    return () => {
      vid.pause();
      videoRef.current = null;
    };
  }, [src, isVideo]);

  /**
   *  Diese Funktion zeichnet das Bild oder Video
   *  zunächst "normal" auf den Canvas,
   *  um dann in Streifen zerschnitten erneut zu zeichnen.
   */
  const drawGlitch = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Canvas an Größe anpassen
    canvas.width = width;
    canvas.height = height;

    // Abwechselnd "hue-rotate" einstellen,
    // falls wir layers > 1 haben, kann jede Ebene
    // einen anderen Hue-Wert kriegen.
    // => So erhält jeder Layer eine andere Farb- / Sättigung
    // (z.B. Layer 0 hat +0°, Layer 1 hat +colorShift°, Layer 2 hat +2*colorShift°, etc.)
    // Du kannst hier aber auch random() nehmen, wenn du willst.
    const layerIndex = Math.floor(random(`layer-${frame}`) * layers);
    const hueValue = layerIndex * colorShift;
    ctx.filter = `hue-rotate(${hueValue}deg)`;

    // Bild oder Video zeichnen
    if (isVideo && videoRef.current) {
      ctx.drawImage(videoRef.current, 0, 0, width, height);
    } else {
      // Bild laden (kannst du auch vorab cachen,
      // hier pro Frame neu => evtl. Performance-Thema)
      const img = new Image();
      img.src = src;
      // Wir zeichnen das Bild erst, wenn es geladen ist
      if (img.complete) {
        ctx.drawImage(img, 0, 0, width, height);
      } else {
        img.onload = () => {
          ctx.drawImage(img, 0, 0, width, height);
        };
      }
    }

    // Falls der aktuelle Frame NICHT dem Glitch-Frequenz-Muster entspricht,
    // kann man *denselben* Zufallszustand wie zuvor beibehalten.
    // => Du kannst hier z.B. "let seedFrame = Math.floor(frame / glitchFrequency)" verwenden.
    // => So glitched das Bild nur alle X Frames „neu“.
    const seedFrame = Math.floor(frame / glitchFrequency);

    // Jetzt die Streifen zufällig verschieben
    const sliceHeight = height / numberOfSlices;
    for (let i = 0; i < numberOfSlices; i++) {
      const sy = i * sliceHeight;
      const sh = sliceHeight;

      const r = random(`f-${seedFrame}-slice-${i}`);
      // glitchIntensity = ±(glitchIntensity * r)
      const glitchXShift = r * glitchIntensity * 2 - glitchIntensity;

      // drawImage-Parameter:
      //  Quelle: (x=0, y=sy, w=width, h=sh)
      //  Ziel: (x=glitchXShift, y=sy, w=width, h=sh)
      ctx.drawImage(canvas, 0, sy, width, sh, glitchXShift, sy, width, sh);
    }
  }, [
    frame,
    width,
    height,
    src,
    isVideo,
    glitchIntensity,
    numberOfSlices,
    glitchFrequency,
    colorShift,
    layers,
  ]);

  // Hier führen wir das Zeichnen in jedem Frame durch
  useEffect(() => {
    drawGlitch();
  }, [drawGlitch, frame]);

  // Jetzt wollen wir mehrere "Layer" übereinander renderverschachteln.
  // Idee: Wir legen einfach mehrmals dieselbe Canvas-Komponente übereinander,
  // aber mit unterschiedlicher colorShift, etc.
  // -> Hier gehen wir aber einen anderen Weg:
  //    Wir lösen das layering in EINER Komponente (z.B. mit `layers`).
  //    Also rendern wir *innerhalb* der Canvas pro Layer neu.
  // => simpler Trick: Wir kopieren den Canvas-Inhalt selbst,
  //    aber mit angepasstem hue-rotate.
  //    Oder wir lassen in der übergeordneten Komponente
  //    mehrere <GlitchCanvasExtended> übereinander.
  //
  // In diesem Snippet machen wir es so,
  // dass wir EINE Canvas pro Komponente haben.
  // Wenn du mehrere Layer in *separaten* Canvas-Elementen willst,
  // kannst du das einfach über `<AbsoluteFill>`+Stapelung lösen.

  return (
    <canvas
      ref={canvasRef}
      style={{
        display: "block",
        width,
        height,
        // Evtl. noch 'mixBlendMode' setzen, wenn du mehrere
        // GlitchCanvasExtended übereinanderlegst:
        // mixBlendMode: 'difference',
        ...style,
      }}
    />
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
