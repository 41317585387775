import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { QRCodeSVG } from "qrcode.react";
import {
  AbsoluteFill,
  staticFile,
  useCurrentFrame,
  useVideoConfig,
  Audio,
  Loop,
  OffthreadVideo,
} from "remotion";
import { InputProps } from "@/remotion/Root";
import "./styles.scss";
import { Ascii3DModel } from "./components/Ascii3DModel";
import { Animated, Move, Scale } from "remotion-animated";
import { GlitchCanvasExtended } from "./components/GlitchCanvasExtended";
import { PHRASES } from "./phrases";

export type GameNftCompositionProps = InputProps & {
  asciiCharacters: string;
  resolution: number;
  audioNumber: number;
  audioSpeechNumber: number;
  binural: number;
};

function timestampToBase64(timestamp: number): string {
  return btoa(timestamp.toString());
}

let data = PHRASES[0];
data = PHRASES[1];
data = PHRASES[2];
data = PHRASES[3];
data = PHRASES[4]; // alien
data = PHRASES[5]; // awake
data = PHRASES[6]; // chaos
data = PHRASES[7]; // change
data = PHRASES[8]; // future
data = PHRASES[9]; // energy
data = PHRASES[10]; // satoshi
data = PHRASES[11]; // abandon

export const GameNftComposition = (props: GameNftCompositionProps) => {
  const { fps } = useVideoConfig();

  return (
    <AbsoluteFill className={clsx(`flex items-center justify-center`)}>
      <div
        className={clsx(`${data.bgColor} absolute top-0 left-0 w-full h-full`)}
      ></div>
      <div
        style={{
          background: `url(${staticFile(`game/1b2990c2f3fd26ad.gif`)})`,
        }}
        className={`z-10 opacity-10 absolute top-0 left-0 w-full h-full pointer-events-none`}
      ></div>

      {data.stlPath && data.stlPath.length > 0 && (
        <Animated
          className="absolute w-full h-full top-0 left-0"
          animations={[
            //Scale({ by: 3, initial: 3 }),
            Move({
              x: 0,
              initialX: -Math.floor(Math.random() * 11) + 1,
              start: 0,
            }),
            Scale({ by: 1.15, initial: 1.1, start: 0 }),
          ]}
        >
          <Ascii3DModel
            asciiCharacters={props.asciiCharacters}
            resolution={props.resolution}
            stlFilePath={data.stlPath}
            cameraPosition={{ ...data.cameraPosition }}
          />
        </Animated>
      )}

      <AbsoluteFill style={{ backgroundColor: "black", opacity: 0.9 }}>
        <Animated
          style={{ position: "absolute", width: "100%", height: "100%" }}
          animations={[
            Scale({ by: data.qr.scale, initial: data.qr.scale, start: 0 }),
            Move({
              x: data.qr.moveX,
              initialX: data.qr.moveX,
              y: data.qr.moveY,
              initialY: data.qr.moveY,
              start: 0,
            }),
          ]}
        >
          <Loop durationInFrames={fps * 4}>
            <OffthreadVideo
              className="z-10"
              src={staticFile(`${data.qr.path}.MP4`)}
            ></OffthreadVideo>
          </Loop>
        </Animated>
      </AbsoluteFill>

      {data.layers &&
        data.layers.map((layer, index) => {
          return (
            <AbsoluteFill key={index}>
              <GlitchCanvasExtended
                src={staticFile(`${layer.path}-reversed.MP4`)}
                isVideo={true}
                numberOfSlices={layer.numberOfSlices}
                glitchIntensity={layer.glitchIntensity}
                glitchFrequency={layer.glitchFrequency}
                style={layer.style as React.CSSProperties}
                colorShift={10}
              />
            </AbsoluteFill>
          );
        })}

      <Animated
        style={{ opacity: 0.051, mixBlendMode: "difference" }}
        animations={[
          //Scale({ by: 3, initial: 3 }),
          Move({
            x: -Math.floor(Math.random() * 11) + 1,
            initialX: -Math.floor(Math.random() * 11) + 1,
            start: 0,
          }),
          //Scale({ by: 1.15, initial: 1.1, start: 0 }),
        ]}
      >
        <pre
          className="glitch text-[24px] block my-8"
          data-text="bc1q0rcauc04lgecq95wufxvswm8swsk2vgjjfdp8r"
        >
          bc1q0rcauc04lgecq95wufxvswm8swsk2vgjjfdp8r
        </pre>
      </Animated>

      <Audio
        volume={0.5}
        src={staticFile(`game/audio/${props.audioNumber}.flac`)}
        loop
      />
      <Audio
        volume={0.3}
        src={staticFile(`game/audio/speech-${props.audioSpeechNumber}.mp3`)}
        loop
      />
      <Audio
        volume={0.3}
        src={staticFile(`game/audio/binural${props.audioSpeechNumber}.flac`)}
        loop
      />
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
