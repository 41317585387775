import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";
import { AsciiEffect } from "three/examples/jsm/effects/AsciiEffect.js";
import {
  AbsoluteFill,
  continueRender,
  delayRender,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";

type Ascii3DModelProps = {
  stlFilePath: string;
  asciiCharacters?: string;
  resolution?: number;
  cameraPosition?: { x: number; y: number; z: number };
};

export const Ascii3DModel: React.FC<Ascii3DModelProps> = ({
  stlFilePath,
  //asciiCharacters = " .:-+*=%@#",
  asciiCharacters = " .:-123",
  resolution = 0.15, // Reduziert die Auflösung für größere Buchstaben,
  cameraPosition = { x: 0, y: 0, z: 500 },
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [handleModelInitialize] = useState(() =>
    delayRender("Initialize Model")
  );
  const frame = useCurrentFrame();
  const { fps, height, width } = useVideoConfig();

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height);

    // ASCII Effect setup
    const effect = new AsciiEffect(renderer, asciiCharacters, {
      invert: true,
      resolution,
    });
    effect.setSize(width, height);
    effect.domElement.style.position = "absolute";
    effect.domElement.style.top = "0";
    effect.domElement.style.left = "0";
    effect.domElement.style.width = "100%";
    effect.domElement.style.height = "100%";
    effect.domElement.style.zIndex = "10";
    effect.domElement.style.opacity = "1";
    effect.domElement.style.backgroundColor = "transparent";
    effect.domElement.style.color = "white";

    container.appendChild(effect.domElement);

    // Scene setup
    const scene = new THREE.Scene();

    // Camera setup
    const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 2000);
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    //camera.lookAt(100, 0, 0);

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);

    // Load STL model
    const stlLoader = new STLLoader();
    const material = new THREE.MeshStandardMaterial({
      color: 0xffffff,
      flatShading: true,
      side: THREE.DoubleSide,
    });

    const mesh = new THREE.Mesh();
    stlLoader.load(
      stlFilePath,
      (geometry) => {
        console.log("STL model loaded successfully.");
        geometry.computeVertexNormals();
        geometry.center();
        geometry.scale(1, 1, 1);

        mesh.geometry = geometry;
        mesh.material = material;
        mesh.rotation.x = -Math.PI / 2;

        scene.add(mesh);

        const controls = new OrbitControls(camera, effect.domElement);
        controls.enableDamping = true;

        const updateFrame = () => {
          const rotationSpeed = (2 * Math.PI) / (fps * 3); // Rotiert das Modell über die gesamte Dauer von 3 Sekunden
          mesh.rotation.y = frame * rotationSpeed;
          mesh.rotation.x = frame * rotationSpeed * 0.5; // Zusätzliche Rotation entlang der X-Achse
          controls.update();
          effect.render(scene, camera);
        };

        setIsModelLoaded(true);
        updateFrame();
      },
      undefined,
      (error) => {
        console.error("Error loading STL file:", error);
      }
    );

    return () => {
      console.log("Cleaning up...");
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
      renderer.dispose();
    };
  }, [stlFilePath, asciiCharacters, resolution, frame, fps]);

  useEffect(() => {
    if (isModelLoaded) {
      continueRender(handleModelInitialize);
    }
  }, [isModelLoaded]);

  return (
    <AbsoluteFill ref={containerRef} className="">
      {!isModelLoaded && (
        <div
          style={{
            color: "white",
            fontSize: "24px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {/* Loading 3D Model... */}
        </div>
      )}
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
