import { staticFile } from "remotion";

export const PHRASES = [
  {
    stlPath: staticFile("game/stl/UFOtop.stl"), // light
    cameraPosition: { x: 0, y: 0, z: 0 },
    phrase: "universe",
    bgColor: "bg-black",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.6,
      moveX: 20,
      moveY: 0,
      path: "game/qr18",
    },
    layers: [
      {
        path: "game/words/universe/video3",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/universe/video2",
        numberOfSlices: 33,
        glitchIntensity: 20,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      /*  {
        path: "game/words/universe/video4",
        numberOfSlices: 67,
        glitchIntensity: 40,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      }, */
    ],
    message: "bc1q0rcauc04lgecq95wufxvswm8swsk2vgjjfdp8r",
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"), // virus
    cameraPosition: { x: 0, y: 0, z: 250 },
    phrase: "light",
    bgColor: "bg-black",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.7,
      moveX: 30,
      moveY: -10,
      path: "game/words/light/qr3",
    },
    layers: [
      {
        path: "game/words/light/video",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/light/video5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/light/light",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
    message: "1BY8GQbnueYofwSuFAT3USAhGjPrkxDdW9",
  },
  {
    range: [0.005, 0.01],
    stlPath: staticFile("game/stl/chichen_itza.stl"), // ancient
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "connect",
    bgColor: "bg-black",
    message:
      "02e0a8b039282faf6fe0fd769cfbc4b6b4cf8758ba68220eac420e32b91ddfa673",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.5,
      moveX: 0,
      moveY: 0,
      path: "game/qr6",
    },
    layers: [
      {
        path: "game/words/connect/video5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/connect/video4",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/connect/video3",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "ancient",
    bgColor: "bg-black",
    message: "14u4nA5sugaswb6SZgn5av2vuChdMnD9E5",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.3,
      moveX: 0,
      moveY: 0,
      path: "game/words/ancient/qr",
    },
    layers: [
      {
        path: "game/words/ancient/video5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/ancient/video2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/ancient/video7",
        numberOfSlices: 56,
        glitchIntensity: 44,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "alien",
    bgColor: "bg-black",
    message: "19z6waranEf8CcP8FqNgdwUe1QRxvUNKBG",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.35,
      moveX: 10,
      moveY: -30,
      path: "game/words/alien/qr",
    },
    layers: [
      {
        path: "game/words/alien/video2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/alien/video",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/alien/video5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"),
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "awake",
    bgColor: "bg-black",
    message: "14JHoRAdmJg3XR4RjMDh6Wed6ft6hzbQe9",
    asciiLetters: " .:-bc1q0",
    qr: {
      scale: 1.45,
      moveX: 0,
      moveY: -20,
      path: "game/words/awake/qr",
    },
    layers: [
      {
        path: "game/words/awake/video",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/awake/video2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/awake/video3",
        numberOfSlices: 44,
        glitchIntensity: 30,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/ancient/video9",
        numberOfSlices: 44,
        glitchIntensity: 30,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.1,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"),
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "chaos",
    bgColor: "bg-black",
    message: "1FTpAbQa4h8trvhQXjXnmNhqdiGBd1oraE",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.7,
      moveX: 15,
      moveY: -30,
      path: "game/words/chaos",
    },
    layers: [
      {
        path: "game/words/chaos/video",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/chaos/video2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/chaos4",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/chichen_itza.stl"),
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "change",
    bgColor: "bg-black",
    message:
      "035cd1854cae45391ca4ec428cc7e6c7d9984424b954209a8eea197b9e364c05f6",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.8,
      moveX: 10,
      moveY: -20,
      path: "game/words/change",
    },
    layers: [
      {
        path: "game/words/change2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/change5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/change6",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "screen",
          opacity: 0.7,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/UFOtop.stl"),
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "future",
    bgColor: "bg-black",
    message: "1NgVmsCCJaKLzGyKLFJfVequnFW9ZvnMLN",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.9,
      moveX: 40,
      moveY: -110,
      path: "game/words/future2",
    },
    layers: [
      {
        path: "game/words/future4",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/words/future5",
        numberOfSlices: 20,
        glitchIntensity: 20,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 50,
      },
      {
        path: "game/words/future6",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "screen",
          opacity: 0.5,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/skull.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "energy",
    bgColor: "bg-black",
    message: "18192XpzzdDi2K11QVHR7td2HcPS6Qs5vg",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.8,
      moveX: 20,
      moveY: -40,
      path: "game/qr14",
    },
    layers: [
      {
        path: "game/future-bg",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/energy-ball",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/energy-funke",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/laugh",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "multiply",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/pilz",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "satoshi",
    bgColor: "bg-black",
    message: "1LuUHyrQr8PKSvbcY1v1PiuGuqFjWpDumN",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.4,
      moveX: 0,
      moveY: 0,
      path: "game/qr7",
    },
    layers: [
      {
        path: "game/ball",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.8)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/water",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/btc",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.6)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
  {
    stlPath: staticFile("game/stl/virus.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "abandon",
    bgColor: "bg-black",
    message: "13Q84TNNvgcL3HJiqQPvyBb9m4hxjS3jkV",
    asciiLetters: " .:-123",
    qr: {
      scale: 1.75,
      moveX: 0,
      moveY: 0,
      path: "game/qr11",
    },
    layers: [
      {
        path: "game/abandon3",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/abandon1",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1.2)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/abandon2",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/abandon5",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1)",
          mixBlendMode: "difference",
          opacity: 0.99,
        },
        colorShift: 10,
      },
      {
        path: "game/abandon4",
        numberOfSlices: 80,
        glitchIntensity: 50,
        glitchFrequency: 1, // FPS
        style: {
          transform: "scale(1)",
          mixBlendMode: "screen",
          opacity: 0.99,
        },
        colorShift: 10,
      },
    ],
  },
];

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
