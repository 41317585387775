import { useEffect, useState } from "react";
import clsx from "clsx";
import { QRCodeSVG } from "qrcode.react";
import {
  AbsoluteFill,
  cancelRender,
  continueRender,
  delayRender,
  random,
  staticFile,
  useCurrentFrame,
  useVideoConfig,
  Audio,
} from "remotion";
import { InputProps } from "@/remotion/Root";
import "./styles.scss";
import { Ascii3DModel } from "./components/Ascii3DModel";
import { Animated, Move, Scale } from "remotion-animated";

export type GameCompositionProps = InputProps & {
  balance: number;
  txs: number;
  btcAddress: string;
  asciiCharacters: string;
  resolution: number;
  audioNumber: number;
  audioSpeechNumber: number;
};

const PHRASES = [
  {
    level: 0,
    range: [0, 0.001],
    stlPath: staticFile("game/stl/sun.stl"), // light
    cameraPosition: { x: 0, y: 0, z: 1000 },
    phrase: "universe",
    bgColor: "bg-black",
    message: "txs comms",
    asciiLetters: " .:-123",
  },
  {
    level: 1,
    range: [0.001, 0.005],
    stlPath: staticFile("game/stl/virus.stl"), // virus
    cameraPosition: { x: 0, y: 0, z: 250 },
    phrase: "light",
    bgColor: "bg-red-900",
    message: "right path, 324626412131631261 the key, youtu.be/MQ9QDquHgTk?t=5",
    asciiLetters: " .:-123",
  },
  {
    level: 2,
    range: [0.005, 0.01],
    stlPath: staticFile("game/stl/chichen_itza.stl"), // ancient
    cameraPosition: { x: 0, y: 0, z: 100 },
    phrase: "connect",
    bgColor: "bg-gray-800",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 3,
    range: [0.01, 0.02],
    stlPath: staticFile("game/stl/UFOtop.stl"), // alien
    cameraPosition: { x: 0, y: 0, z: 250 },
    phrase: "ancient",
    bgColor: "bg-gray-700",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 4,
    range: [0.02, 0.05],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "alien",
    bgColor: "bg-gray-600",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 5,
    range: [0.05, 0.1],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "awake",
    bgColor: "bg-gray-500",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 6,
    range: [0.1, 0.2],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "chaos",
    bgColor: "bg-gray-400",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 7,
    range: [0.2, 0.3],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "change",
    bgColor: "bg-gray-300",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 8,
    range: [0.3, 0.5],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "future",
    bgColor: "bg-gray-200",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 9,
    range: [0.5, 0.7],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "energy",
    bgColor: "bg-gray-100",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 10,
    range: [0.7, 1.0],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "eternal",
    bgColor: "bg-white",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 11,
    range: [1.0, 1.3],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "abandon",
    bgColor: "bg-yellow-100",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 12,
    range: [1.3, 1.7],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "balance",
    bgColor: "bg-yellow-200",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 13,
    range: [1.7, 2.2],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "satoshi",
    bgColor: "bg-yellow-300",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 14,
    range: [2.2, 2.8],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "believe",
    bgColor: "bg-yellow-400",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 15,
    range: [2.8, 3.5],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "crystal",
    bgColor: "bg-yellow-500",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 16,
    range: [3.5, 4.3],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "divide",
    bgColor: "bg-yellow-600",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 17,
    range: [4.3, 5.2],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "evolve",
    bgColor: "bg-yellow-700",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 18,
    range: [5.2, 6.2],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "faith",
    bgColor: "bg-yellow-800",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 19,
    range: [6.2, 7.3],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "mystery",
    bgColor: "bg-yellow-900",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 20,
    range: [7.3, 8.5],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "gold",
    bgColor: "bg-orange-900",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 21,
    range: [8.5, 9.2],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "reveal",
    bgColor: "bg-orange-800",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 22,
    range: [9.2, 10.0],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "ripple",
    bgColor: "bg-orange-700",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 23,
    range: [10.0, 20.0],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "gauge",
    bgColor: "bg-orange-600",
    message: "",
    asciiLetters: " .:-123",
  },
  {
    level: 24,
    range: [20.0, Infinity],
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    phrase: "5e5aed72d358a3d9",
    bgColor: "bg-green-500",
    message: "",
    asciiLetters: " .:-123",
  },
];

function getLevelAndProgress(bitcoinBalance: number, txs: number) {
  const currentLevel = PHRASES.find(
    (p) => bitcoinBalance >= p.range[0] && bitcoinBalance < p.range[1]
  ) || {
    level: 0,
    phrase: "No txs",
    range: [0, 0],
    bgColor: "bg-black",
    stlPath: staticFile("game/stl/bitcoin.stl"),
    cameraPosition: { x: 0, y: 0, z: 50 },
    message: "",
    asciiLetters: " .:-123",
  };

  const phrases = PHRASES.filter((p) => p.level <= currentLevel.level).map(
    (p) => p.phrase
  );

  const prevRange = PHRASES[currentLevel.level - 1]?.range || [0, 0];
  const progress =
    (bitcoinBalance - prevRange[0]) / (currentLevel.range[1] - prevRange[0]);

  const progressBar = createProgressBar(progress);
  const comment =
    txs > 0
      ? `${currentLevel.message} Level ${currentLevel.level} - ${txs} txs`
      : `proc txs to reveal nd comms - ${txs} txs`;

  return {
    level: currentLevel.level,
    phrases,
    comment,
    progress,
    progressBar,
    bgColor: currentLevel.bgColor,
    stlPath: currentLevel.stlPath,
    cameraPosition: currentLevel.cameraPosition,
  };
}

function createProgressBar(progress: number) {
  const totalBars = 10;
  const filledBars = Math.round(progress * totalBars);
  const emptyBars = totalBars - filledBars;

  return `[${"█".repeat(filledBars)}${"-".repeat(emptyBars)}] ${Math.round(
    progress * 100
  )}%`;
}

function timestampToBase64(timestamp: number): string {
  return btoa(timestamp.toString());
}

export const GameComposition = (props: GameCompositionProps) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();
  const [data, setData] = useState<{
    level: number;
    phrases: string[];
    comment: string;
    progressBar: string;
    progress: number;
    txs: number;
    bgColor: string;
    stlPath: string;
    cameraPosition: { x: number; y: number; z: number };
  }>({
    level: 0,
    phrases: [],
    comment: "Loading...",
    progressBar: "[----------] 0%",
    txs: 0,
    bgColor: "bg-black",
    stlPath: "",
    cameraPosition: { x: 0, y: 0, z: 50 },
    progress: 0,
  });
  const [handleDataInitialize] = useState(() =>
    delayRender("Initialize Videos")
  );

  useEffect(() => {
    const getGameData = async () => {
      try {
        const bitcoinBalance = props.balance / 100000000;

        const {
          level,
          phrases,
          comment,
          progressBar,
          bgColor,
          stlPath,
          cameraPosition,
          progress,
        } = getLevelAndProgress(bitcoinBalance, props.txs);

        setData({
          level,
          phrases,
          comment,
          progressBar,
          txs: props.txs,
          bgColor,
          stlPath,
          cameraPosition,
          progress,
        });
        continueRender(handleDataInitialize);
      } catch (error) {
        console.error("Error fetching Bitcoin data:", error);
        setData({
          level: 0,
          phrases: [],
          comment: "Error",
          progress: 0,
          progressBar: "[----------] 0%",
          txs: 0,
          bgColor: "bg-black",
          stlPath: "game/stl/bitcoin.stl",
          cameraPosition: { x: 0, y: 0, z: 50 },
        });
        cancelRender(error);
      }
    };

    getGameData();
  }, []);

  // Starte mit einem Basis-Timestamp (z. B. jetzt)
  const startTime = Date.now(); // Zeit in Millisekunden

  const currentTimestamp = Math.floor(startTime + (frame / fps) * 1000);

  const qrValue = `${props.btcAddress} - ${data.comment} - ${JSON.stringify(
    data.phrases.join(", ")
  )} - ${timestampToBase64(currentTimestamp)}`;

  //console.log(qrValue);

  const scaledZ = data.cameraPosition.z - 150 * (1 - data.progress);

  //console.log(scaledZ);

  return (
    <AbsoluteFill className={clsx(`flex items-center justify-center`)}>
      <div
        className={clsx(`${data.bgColor} absolute top-0 left-0 w-full h-full`)}
      ></div>
      <div
        style={{
          background: `url(${staticFile(`game/1b2990c2f3fd26ad.gif`)})`,
        }}
        className={`z-10 opacity-25 absolute top-0 left-0 w-full h-full pointer-events-none`}
      ></div>

      {data.stlPath && data.stlPath.length > 0 && (
        <Animated
          className="absolute w-full h-full top-0 left-0"
          animations={[
            //Scale({ by: 3, initial: 3 }),
            Move({
              x: 0,
              initialX: -Math.floor(Math.random() * 11) + 1,
              start: 0,
            }),
            Scale({ by: 1.15, initial: 1.1, start: 0 }),
          ]}
        >
          <Ascii3DModel
            asciiCharacters={props.asciiCharacters}
            resolution={props.resolution}
            stlFilePath={data.stlPath}
            cameraPosition={{ ...data.cameraPosition, z: scaledZ }}
          />
        </Animated>
      )}

      <div className="glitch-wrapper flex-col w-full">
        <Animated
          animations={[
            //Scale({ by: 3, initial: 3 }),
            Move({
              y: -Math.floor(Math.random() * 11) + 1,
              initialY: -Math.floor(Math.random() * 11) + 1,
              start: 0,
            }),
            //Scale({ by: 1.15, initial: 1.1, start: 0 }),
          ]}
        >
          <div
            className="glitch text-center text-[60px] md:text-[100px] uppercase"
            data-text={
              data.level > 0 ? `Level ${data.level}` : `Level ${data.level}`
            }
          >
            {data.level > 0 ? `Layer ${data.level}` : `Layer ${data.level}`}
          </div>
        </Animated>

        <Animated
          animations={[
            //Scale({ by: 3, initial: 3 }),
            /* Move({
              x: 0,
              initialX: -Math.floor(Math.random() * 11) + 1,
              start: 0,
            }), */
            Scale({ by: 1.05, initial: 1, start: 0 }),
          ]}
        >
          <QRCodeSVG
            className="z-10"
            bgColor="transparent"
            fgColor="white"
            value={qrValue}
            level="H"
            size={400}
          />
        </Animated>

        <Animated
          animations={[
            //Scale({ by: 3, initial: 3 }),
            Move({
              x: -Math.floor(Math.random() * 11) + 1,
              initialX: -Math.floor(Math.random() * 11) + 1,
              start: 0,
            }),
            //Scale({ by: 1.15, initial: 1.1, start: 0 }),
          ]}
        >
          <pre
            className="glitch text-[14px] md:text-[34px] block my-8"
            data-text={props.btcAddress}
          >
            {props.btcAddress}
          </pre>
        </Animated>

        <Animated
          animations={[
            Move({
              y: Math.floor(Math.random() * 11) + 1,
              initialY: Math.floor(Math.random() * 11) + 1,
              start: 0,
            }),
          ]}
        >
          <pre
            className={clsx("glitch-next text-[16px] md:text-[34px]", {
              "text-green-500": data.txs > 0,
              "text-black": data.txs === 0,
            })}
            data-text={`${data.txs} txs`}
          >
            {data.progressBar}
          </pre>
        </Animated>
      </div>
      <Audio
        volume={0.5}
        src={staticFile(`game/audio/${props.audioNumber}.flac`)}
        loop
      />
      <Audio
        volume={0.3}
        src={staticFile(`game/audio/speech-${props.audioSpeechNumber}.mp3`)}
        loop
      />
    </AbsoluteFill>
  );
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
